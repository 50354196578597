import React, {useEffect, useState, useGlobal} from "reactn";
import {useParams} from 'react-router-dom'
import {Row, Col, Form, Input, Button, Switch, Card, message, InputNumber, Alert} from 'antd';
import {useHistory} from 'react-router-dom';
import {LeftOutlined} from "@ant-design/icons";
import {GroupPicker, LangPicker, ImageUrl,MultipleLangPicker} from '../components';
import api from '../service/index'
import {Link} from 'react-router-dom';
import {useCallback} from "react";

const Activity2CategoryDetail = (props) => {
    let params = useParams()
    let history = useHistory()
    let id = params.id !== "add" ? params.id : false;
    let [langCms] = useGlobal("langCms"); 
    let [langCode] = useGlobal("langCode"); 
    let newRecord = {
        langs: [{ lang: langCode ? langCode : langCms ? langCms[0].code : "TR", title: "" }],
        lang: langCode ? langCode : langCms? langCms[0].code : "",
        name: '',
        order: 0,
        active: true,
        imageUrl: '',
        groups: []
    }
    let [data, setData] = useState(id ? {} : newRecord);
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(id ? true : false);
    let [validationCheck, setValidationCheck] = useState(false)
    let [isSmall, ] = useGlobal('isSmall')

    let [modules] = useGlobal("modules");
    let [languagesCms] = useGlobal("languagesCms"); 

    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

     
    useEffect(() => {
        if (modules && id) {
            setLoading(true)
            api.get("/rest/activity2Category/" + id).then(({data: {result, result_message}}) => {
                setData(result);
                setLoading(false)
            });
        }
    }, [id]);

    let validate = useCallback(() => {
        let errors = {};

        if (data.lang == null)
            errors.lang = languagesCms.ENFORCED

        if (data.active == null)
            errors.active = languagesCms.ENFORCED

        // if (data.name == null || data.name.length === 0)
        //     errors.name = languagesCms.ENFORCED

        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    useEffect(() => {
        if (validationCheck) setErrors(validate())
    }, [validationCheck, data, validate]);

    let save = async () => {
        setValidationCheck(true)
        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({top: 20, behavior: 'smooth'});
        } else {
            if (id) {
                api.put("/rest/activity2Category/" + id, data).then(({data: {result, result_message}}) => {
                    if (result_message.type === 'success') {
                        message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
                        history.push('/activity2')
                    } else
                        message.error(languagesCms.ERROR_SAVE_RECORD, 2);
                })
            } else {
                api.post("/rest/activity2Category", data).then(({data: {result, result_message}}) => {
                    if (result_message.type === 'success') {
                        message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
                        history.push('/activity2')
                    } else {
                        message.error(result_message.message, 2);
                    }
                })
            }
        }
    };

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/activity2">
                        <Button type="light" icon={<LeftOutlined/>}
                                size={ 'large'}>{!isSmall && languagesCms.BACK}</Button>
                    </Link>
                </div>
            </div>
            <div className="form-wrap">

                <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
                    <Form layout="horizontal" size={"large"} onFinish={save}>
                        <Row direction="row">
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.LANG}>
                                    <LangPicker langCms={langCms} langCode={langCode} languagesCms={languagesCms} record={data} setRecord={setData} name="lang"/>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.GROUP}>
                                    <GroupPicker languagesCms={languagesCms} record={data} setRecord={setData} name="groups"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <MultipleLangPicker langCms={langCms} langCode={langCode} languagesCms={languagesCms}  title={'title'}  record={data} setRecord={setData} name="lang" />
                </Col>
            </Row>
                        {/* <Row direction="row">
                            <Col xs={{span: 24}} md={{span: 24}}>
                                <Form.Item label={languagesCms.TITLE} required help={errors.name}
                                           validateStatus={errors.name ? 'error' : 'success'}>
                                    <Input name="name" value={data.name}
                                           onChange={e => setData({...data, name: e.target.value})}/>
                                </Form.Item>
                            </Col>
                        </Row> */}
                        <Row direction="row">
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.STATUS} required help={errors.active}
                                           validateStatus={errors.active ? 'error' : 'success'}>
                                    <Switch checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE}
                                            unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({...data, active: v})}/>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.ORDER} help={errors.order}
                                           validateStatus={errors.order ? 'error' : 'success'}>
                                    <InputNumber name="order" value={data.order}
                                                 onChange={v => setData({...data, order: v})}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col span={24}>
                                <Form.Item label={languagesCms.MEDIA} help={errors.imageUrl}
                                           validateStatus={errors.imageUrl ? 'error' : 'success'}>
                                    <ImageUrl record={data} setRecord={setData} name="imageUrl"/>
                                    <Alert message={languagesCms.PHOTO_SIZE_MESSAGE} banner/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" disabled={loading} htmlType="submit" size="large"
                                            block> {languagesCms.SAVE} </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div>
        </div>
    );
};


export default Activity2CategoryDetail;
